<template>
    <div class="wrap">
        <div class="users-box">
            <div class="users-item"><router-link class="lias" to="/myself">{{$t('message.accountinfo')}}<span class="el-icon-arrow-right"></span></router-link></div>
            <div class="users-item"><router-link class="lias" to="/myorder">{{$t('message.myorder')}}<span class="el-icon-arrow-right"></span></router-link></div>
            <div class="users-item"><router-link class="lias" to="/myaddress">{{$t('message.addressfrom')}}<span class="el-icon-arrow-right"></span></router-link></div>
        </div>
        <div class="users-box">
            <div class="users-item" @click="getOutLogin">{{$t('message.outlog')}}</div>
        </div>
    </div>
</template>
<script>
import {getLocalItem,delLocalItem} from '@/untils/common';
export default {
    name:'users',
    inject:['reload'],
    methods: {
        getOutLogin(){
            this.$store.commit('setLoginNameStatus', {});
            delLocalItem('login_user_info');
            this.$root.Bus.$emit('userInfoClick', {});
            this.$router.push({path:'/'});
            this.reload();
        }
    },
    created(){
        let token = getLocalItem('login_user_info')&&getLocalItem('login_user_info').token?getLocalItem('login_user_info').token:'';
        if(token){
            
        }else{
            this.$router.push({path:'/'});
            return false;
        }
    }
}
</script>
<style lang="scss" scoped>
.wrap{
    width: 100%;
    height: 100%;
    background: #F4F7FE;

}
.users-box{
    margin-bottom: 10px;
    padding:10px;
    background: #fff;
    .users-item{
        padding:10px 0;
        .lias{
            display: block;
            text-decoration: none;
            color: #212529;
            position: relative;
            .el-icon-arrow-right{
                position: absolute;
                right: 0;
                top: 2px;
                font-size:20px;
            }
        }
    }
}
</style>